<script>
var SHA256 = require("crypto-js/sha256");

function OnRegisterResponse(ctx, response)
{
  var result = response.data.msg;
  ctx.waitResponse = false;
  ctx.status = result;

  if(result == "success") {
    ctx.resetInputs();
  }
}


export default {
  name: 'RegisterPage',

  data: function () { return {
    waitResponse: false,
    status: "",
    inputData: {
      f_name: "",
      s_name: "",
      email: "",
      password: "",
      phone: "",
    }
  }},

  methods: {
    register() 
    {
      this.waitResponse = true;
      this.status = "";

      let registerData = {...this.inputData};
      registerData.password = SHA256(this.inputData.password).toString();
      this.$httpPOST(this, `register`, OnRegisterResponse, registerData);
    },

    resetInputs() {
      this.inputData =  {
        f_name: "",
        s_name: "",
        email: "",
        password: "",
        phone: "",
      };
    }
  },

  mounted() 
  {
    if (this.$session.active)
    {
      this.$router.push("/");
    }
  },

}
</script>

<template>
  <div class="login-body">
    <form @submit.prevent="register">
      <div class="login-board">
        <h2>Register</h2>
        <input maxlength="20" class="input" placeholder="First name" v-model="inputData.f_name" ref="f_name" required>
        <input maxlength="20" class="input" placeholder="Surname" v-model="inputData.s_name" ref="s_name" required>

        <input maxlength="50" class="input" placeholder="email@domain.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" v-model="inputData.email" required>
        
        <input maxlength="10" class="input" placeholder="Phone" name="phone" pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$" v-model="inputData.phone" ref="phone" required>
        <input maxlength="30" class="input" placeholder="Password" v-model="inputData.password" type="password" ref="password" required>
        <l-button :active="waitResponse" class="button loginBtn">
          Register
        </l-button>
          <hr />
        <router-link class="material-icons button icon-button" to="login">
          arrow_back
        </router-link>  

        <div v-if="status" v-on:click="status=''" :class="['box status-box', {'red': status != 'success'}, {'green': status == 'success' }]">
          {{$lan(status)}}
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped src="./public.css"></style>
